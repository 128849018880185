import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _10ecd936 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _c42f3c16 = () => interopDefault(import('../pages/AboutUs.vue' /* webpackChunkName: "pages/AboutUs" */))
const _4f1adcf1 = () => interopDefault(import('../pages/Brand.vue' /* webpackChunkName: "pages/Brand" */))
const _d0273b5c = () => interopDefault(import('../pages/Brands.vue' /* webpackChunkName: "pages/Brands" */))
const _0ad49e21 = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _566790c7 = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _69996313 = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _0848b51e = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _094d35c6 = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _1a49fd26 = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _c7e83e0c = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _2868639a = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _5e22748a = () => interopDefault(import('../pages/Contact.vue' /* webpackChunkName: "pages/Contact" */))
const _091d3f3a = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _2bce4eb0 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _24ec6903 = () => interopDefault(import('../modules/customer/pages/MyAccount/Backorders.vue' /* webpackChunkName: "" */))
const _6c4a3a76 = () => interopDefault(import('../modules/customer/pages/MyAccount/CreditInvoices.vue' /* webpackChunkName: "" */))
const _7aaa47c6 = () => interopDefault(import('../modules/customer/pages/MyAccount/Invoices.vue' /* webpackChunkName: "" */))
const _71f131bc = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _753e29b8 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _27970228 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _6b0388f0 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _47ab1230 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _208ebec6 = () => interopDefault(import('../modules/customer/pages/MyAccount/ProductFeeds.vue' /* webpackChunkName: "" */))
const _8fd69190 = () => interopDefault(import('../modules/customer/pages/MyAccount/CsvUpload/CsvUpload.vue' /* webpackChunkName: "" */))
const _6fca8918 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _dd2a473a = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _4b41c3e6 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _6939c9ea = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _37409ffb = () => interopDefault(import('../pages/CustomerSupport.vue' /* webpackChunkName: "pages/CustomerSupport" */))
const _2e0a7738 = () => interopDefault(import('../pages/ForgotPassword.vue' /* webpackChunkName: "pages/ForgotPassword" */))
const _4f090d54 = () => interopDefault(import('../pages/Inspiration.vue' /* webpackChunkName: "pages/Inspiration" */))
const _178dbfd3 = () => interopDefault(import('../pages/Login.vue' /* webpackChunkName: "pages/Login" */))
const _47bea915 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _925237ae = () => interopDefault(import('../pages/Register.vue' /* webpackChunkName: "pages/Register" */))
const _1a9c542e = () => interopDefault(import('../pages/Search.vue' /* webpackChunkName: "pages/Search" */))
const _5fd95485 = () => interopDefault(import('../pages/Storyblok.vue' /* webpackChunkName: "pages/Storyblok" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de",
    component: _10ecd936,
    name: "home___de"
  }, {
    path: "/en",
    component: _10ecd936,
    name: "home___en"
  }, {
    path: "/es",
    component: _10ecd936,
    name: "home___es"
  }, {
    path: "/fr",
    component: _10ecd936,
    name: "home___fr"
  }, {
    path: "/nl",
    component: _10ecd936,
    name: "home___nl"
  }, {
    path: "/de/AboutUs",
    component: _c42f3c16,
    name: "AboutUs___de"
  }, {
    path: "/de/Brand",
    component: _4f1adcf1,
    name: "Brand___de"
  }, {
    path: "/de/Brands",
    component: _d0273b5c,
    name: "Brands___de"
  }, {
    path: "/de/cart",
    component: _0ad49e21,
    name: "cart___de"
  }, {
    path: "/de/checkout",
    component: _566790c7,
    name: "checkout___de",
    children: [{
      path: "billing",
      component: _69996313,
      name: "billing___de"
    }, {
      path: "payment",
      component: _0848b51e,
      name: "payment___de"
    }, {
      path: "shipping",
      component: _094d35c6,
      name: "shipping___de"
    }, {
      path: "thank-you",
      component: _1a49fd26,
      name: "thank-you___de"
    }, {
      path: "user-account",
      component: _c7e83e0c,
      name: "user-account___de"
    }]
  }, {
    path: "/de/Cms",
    component: _2868639a,
    name: "Cms___de"
  }, {
    path: "/de/Contact",
    component: _5e22748a,
    name: "Contact___de"
  }, {
    path: "/de/customer",
    component: _091d3f3a,
    meta: {"titleLabel":"My Account"},
    name: "customer___de",
    children: [{
      path: "addresses-details",
      component: _2bce4eb0,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___de"
    }, {
      path: "backorder-items",
      component: _24ec6903,
      meta: {"titleLabel":"Backorders"},
      name: "customer-backorder-items___de"
    }, {
      path: "credit-invoices",
      component: _6c4a3a76,
      meta: {"titleLabel":"Credit Invoices"},
      name: "customer-credit-invoices___de"
    }, {
      path: "invoices",
      component: _7aaa47c6,
      meta: {"titleLabel":"Invoices"},
      name: "customer-invoices___de"
    }, {
      path: "my-newsletter",
      component: _71f131bc,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___de"
    }, {
      path: "my-profile",
      component: _753e29b8,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___de"
    }, {
      path: "my-reviews",
      component: _27970228,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___de"
    }, {
      path: "my-wishlist",
      component: _6b0388f0,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___de"
    }, {
      path: "order-history",
      component: _47ab1230,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___de"
    }, {
      path: "product-feeds",
      component: _208ebec6,
      meta: {"titleLabel":"Product feeds"},
      name: "customer-product-feeds___de"
    }, {
      path: "quick-order",
      component: _8fd69190,
      meta: {"titleLabel":"Quick Order"},
      name: "quick-order___de"
    }, {
      path: "addresses-details/create",
      component: _6fca8918,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___de"
    }, {
      path: "/de/reset-password",
      component: _dd2a473a,
      alias: "/customer/account/createPassword",
      name: "reset-password___de"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _4b41c3e6,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___de"
    }, {
      path: "order-history/:orderId",
      component: _6939c9ea,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___de"
    }]
  }, {
    path: "/de/CustomerSupport",
    component: _37409ffb,
    name: "CustomerSupport___de"
  }, {
    path: "/de/ForgotPassword",
    component: _2e0a7738,
    name: "ForgotPassword___de"
  }, {
    path: "/de/Home",
    component: _10ecd936,
    name: "Home___de"
  }, {
    path: "/de/Inspiration",
    component: _4f090d54,
    name: "Inspiration___de"
  }, {
    path: "/de/Login",
    component: _178dbfd3,
    name: "Login___de"
  }, {
    path: "/de/Page",
    component: _47bea915,
    name: "Page___de"
  }, {
    path: "/de/Register",
    component: _925237ae,
    name: "Register___de"
  }, {
    path: "/de/Search",
    component: _1a9c542e,
    name: "Search___de"
  }, {
    path: "/de/Storyblok",
    component: _5fd95485,
    name: "Storyblok___de"
  }, {
    path: "/en/AboutUs",
    component: _c42f3c16,
    name: "AboutUs___en"
  }, {
    path: "/en/Brand",
    component: _4f1adcf1,
    name: "Brand___en"
  }, {
    path: "/en/Brands",
    component: _d0273b5c,
    name: "Brands___en"
  }, {
    path: "/en/cart",
    component: _0ad49e21,
    name: "cart___en"
  }, {
    path: "/en/checkout",
    component: _566790c7,
    name: "checkout___en",
    children: [{
      path: "billing",
      component: _69996313,
      name: "billing___en"
    }, {
      path: "payment",
      component: _0848b51e,
      name: "payment___en"
    }, {
      path: "shipping",
      component: _094d35c6,
      name: "shipping___en"
    }, {
      path: "thank-you",
      component: _1a49fd26,
      name: "thank-you___en"
    }, {
      path: "user-account",
      component: _c7e83e0c,
      name: "user-account___en"
    }]
  }, {
    path: "/en/Cms",
    component: _2868639a,
    name: "Cms___en"
  }, {
    path: "/en/Contact",
    component: _5e22748a,
    name: "Contact___en"
  }, {
    path: "/en/customer",
    component: _091d3f3a,
    meta: {"titleLabel":"My Account"},
    name: "customer___en",
    children: [{
      path: "addresses-details",
      component: _2bce4eb0,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___en"
    }, {
      path: "backorder-items",
      component: _24ec6903,
      meta: {"titleLabel":"Backorders"},
      name: "customer-backorder-items___en"
    }, {
      path: "credit-invoices",
      component: _6c4a3a76,
      meta: {"titleLabel":"Credit Invoices"},
      name: "customer-credit-invoices___en"
    }, {
      path: "invoices",
      component: _7aaa47c6,
      meta: {"titleLabel":"Invoices"},
      name: "customer-invoices___en"
    }, {
      path: "my-newsletter",
      component: _71f131bc,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___en"
    }, {
      path: "my-profile",
      component: _753e29b8,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___en"
    }, {
      path: "my-reviews",
      component: _27970228,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___en"
    }, {
      path: "my-wishlist",
      component: _6b0388f0,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___en"
    }, {
      path: "order-history",
      component: _47ab1230,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___en"
    }, {
      path: "product-feeds",
      component: _208ebec6,
      meta: {"titleLabel":"Product feeds"},
      name: "customer-product-feeds___en"
    }, {
      path: "quick-order",
      component: _8fd69190,
      meta: {"titleLabel":"Quick Order"},
      name: "quick-order___en"
    }, {
      path: "addresses-details/create",
      component: _6fca8918,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___en"
    }, {
      path: "/en/reset-password",
      component: _dd2a473a,
      alias: "/customer/account/createPassword",
      name: "reset-password___en"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _4b41c3e6,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___en"
    }, {
      path: "order-history/:orderId",
      component: _6939c9ea,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___en"
    }]
  }, {
    path: "/en/CustomerSupport",
    component: _37409ffb,
    name: "CustomerSupport___en"
  }, {
    path: "/en/ForgotPassword",
    component: _2e0a7738,
    name: "ForgotPassword___en"
  }, {
    path: "/en/Home",
    component: _10ecd936,
    name: "Home___en"
  }, {
    path: "/en/Inspiration",
    component: _4f090d54,
    name: "Inspiration___en"
  }, {
    path: "/en/Login",
    component: _178dbfd3,
    name: "Login___en"
  }, {
    path: "/en/Page",
    component: _47bea915,
    name: "Page___en"
  }, {
    path: "/en/Register",
    component: _925237ae,
    name: "Register___en"
  }, {
    path: "/en/Search",
    component: _1a9c542e,
    name: "Search___en"
  }, {
    path: "/en/Storyblok",
    component: _5fd95485,
    name: "Storyblok___en"
  }, {
    path: "/es/AboutUs",
    component: _c42f3c16,
    name: "AboutUs___es"
  }, {
    path: "/es/Brand",
    component: _4f1adcf1,
    name: "Brand___es"
  }, {
    path: "/es/Brands",
    component: _d0273b5c,
    name: "Brands___es"
  }, {
    path: "/es/cart",
    component: _0ad49e21,
    name: "cart___es"
  }, {
    path: "/es/checkout",
    component: _566790c7,
    name: "checkout___es",
    children: [{
      path: "billing",
      component: _69996313,
      name: "billing___es"
    }, {
      path: "payment",
      component: _0848b51e,
      name: "payment___es"
    }, {
      path: "shipping",
      component: _094d35c6,
      name: "shipping___es"
    }, {
      path: "thank-you",
      component: _1a49fd26,
      name: "thank-you___es"
    }, {
      path: "user-account",
      component: _c7e83e0c,
      name: "user-account___es"
    }]
  }, {
    path: "/es/Cms",
    component: _2868639a,
    name: "Cms___es"
  }, {
    path: "/es/Contact",
    component: _5e22748a,
    name: "Contact___es"
  }, {
    path: "/es/customer",
    component: _091d3f3a,
    meta: {"titleLabel":"My Account"},
    name: "customer___es",
    children: [{
      path: "addresses-details",
      component: _2bce4eb0,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___es"
    }, {
      path: "backorder-items",
      component: _24ec6903,
      meta: {"titleLabel":"Backorders"},
      name: "customer-backorder-items___es"
    }, {
      path: "credit-invoices",
      component: _6c4a3a76,
      meta: {"titleLabel":"Credit Invoices"},
      name: "customer-credit-invoices___es"
    }, {
      path: "invoices",
      component: _7aaa47c6,
      meta: {"titleLabel":"Invoices"},
      name: "customer-invoices___es"
    }, {
      path: "my-newsletter",
      component: _71f131bc,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___es"
    }, {
      path: "my-profile",
      component: _753e29b8,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___es"
    }, {
      path: "my-reviews",
      component: _27970228,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___es"
    }, {
      path: "my-wishlist",
      component: _6b0388f0,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___es"
    }, {
      path: "order-history",
      component: _47ab1230,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___es"
    }, {
      path: "product-feeds",
      component: _208ebec6,
      meta: {"titleLabel":"Product feeds"},
      name: "customer-product-feeds___es"
    }, {
      path: "quick-order",
      component: _8fd69190,
      meta: {"titleLabel":"Quick Order"},
      name: "quick-order___es"
    }, {
      path: "addresses-details/create",
      component: _6fca8918,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___es"
    }, {
      path: "/es/reset-password",
      component: _dd2a473a,
      alias: "/customer/account/createPassword",
      name: "reset-password___es"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _4b41c3e6,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___es"
    }, {
      path: "order-history/:orderId",
      component: _6939c9ea,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___es"
    }]
  }, {
    path: "/es/CustomerSupport",
    component: _37409ffb,
    name: "CustomerSupport___es"
  }, {
    path: "/es/ForgotPassword",
    component: _2e0a7738,
    name: "ForgotPassword___es"
  }, {
    path: "/es/Home",
    component: _10ecd936,
    name: "Home___es"
  }, {
    path: "/es/Inspiration",
    component: _4f090d54,
    name: "Inspiration___es"
  }, {
    path: "/es/Login",
    component: _178dbfd3,
    name: "Login___es"
  }, {
    path: "/es/Page",
    component: _47bea915,
    name: "Page___es"
  }, {
    path: "/es/Register",
    component: _925237ae,
    name: "Register___es"
  }, {
    path: "/es/Search",
    component: _1a9c542e,
    name: "Search___es"
  }, {
    path: "/es/Storyblok",
    component: _5fd95485,
    name: "Storyblok___es"
  }, {
    path: "/fr/AboutUs",
    component: _c42f3c16,
    name: "AboutUs___fr"
  }, {
    path: "/fr/Brand",
    component: _4f1adcf1,
    name: "Brand___fr"
  }, {
    path: "/fr/Brands",
    component: _d0273b5c,
    name: "Brands___fr"
  }, {
    path: "/fr/cart",
    component: _0ad49e21,
    name: "cart___fr"
  }, {
    path: "/fr/checkout",
    component: _566790c7,
    name: "checkout___fr",
    children: [{
      path: "billing",
      component: _69996313,
      name: "billing___fr"
    }, {
      path: "payment",
      component: _0848b51e,
      name: "payment___fr"
    }, {
      path: "shipping",
      component: _094d35c6,
      name: "shipping___fr"
    }, {
      path: "thank-you",
      component: _1a49fd26,
      name: "thank-you___fr"
    }, {
      path: "user-account",
      component: _c7e83e0c,
      name: "user-account___fr"
    }]
  }, {
    path: "/fr/Cms",
    component: _2868639a,
    name: "Cms___fr"
  }, {
    path: "/fr/Contact",
    component: _5e22748a,
    name: "Contact___fr"
  }, {
    path: "/fr/customer",
    component: _091d3f3a,
    meta: {"titleLabel":"My Account"},
    name: "customer___fr",
    children: [{
      path: "addresses-details",
      component: _2bce4eb0,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___fr"
    }, {
      path: "backorder-items",
      component: _24ec6903,
      meta: {"titleLabel":"Backorders"},
      name: "customer-backorder-items___fr"
    }, {
      path: "credit-invoices",
      component: _6c4a3a76,
      meta: {"titleLabel":"Credit Invoices"},
      name: "customer-credit-invoices___fr"
    }, {
      path: "invoices",
      component: _7aaa47c6,
      meta: {"titleLabel":"Invoices"},
      name: "customer-invoices___fr"
    }, {
      path: "my-newsletter",
      component: _71f131bc,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___fr"
    }, {
      path: "my-profile",
      component: _753e29b8,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___fr"
    }, {
      path: "my-reviews",
      component: _27970228,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___fr"
    }, {
      path: "my-wishlist",
      component: _6b0388f0,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___fr"
    }, {
      path: "order-history",
      component: _47ab1230,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___fr"
    }, {
      path: "product-feeds",
      component: _208ebec6,
      meta: {"titleLabel":"Product feeds"},
      name: "customer-product-feeds___fr"
    }, {
      path: "quick-order",
      component: _8fd69190,
      meta: {"titleLabel":"Quick Order"},
      name: "quick-order___fr"
    }, {
      path: "addresses-details/create",
      component: _6fca8918,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___fr"
    }, {
      path: "/fr/reset-password",
      component: _dd2a473a,
      alias: "/customer/account/createPassword",
      name: "reset-password___fr"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _4b41c3e6,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___fr"
    }, {
      path: "order-history/:orderId",
      component: _6939c9ea,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___fr"
    }]
  }, {
    path: "/fr/CustomerSupport",
    component: _37409ffb,
    name: "CustomerSupport___fr"
  }, {
    path: "/fr/ForgotPassword",
    component: _2e0a7738,
    name: "ForgotPassword___fr"
  }, {
    path: "/fr/Home",
    component: _10ecd936,
    name: "Home___fr"
  }, {
    path: "/fr/Inspiration",
    component: _4f090d54,
    name: "Inspiration___fr"
  }, {
    path: "/fr/Login",
    component: _178dbfd3,
    name: "Login___fr"
  }, {
    path: "/fr/Page",
    component: _47bea915,
    name: "Page___fr"
  }, {
    path: "/fr/Register",
    component: _925237ae,
    name: "Register___fr"
  }, {
    path: "/fr/Search",
    component: _1a9c542e,
    name: "Search___fr"
  }, {
    path: "/fr/Storyblok",
    component: _5fd95485,
    name: "Storyblok___fr"
  }, {
    path: "/nl/AboutUs",
    component: _c42f3c16,
    name: "AboutUs___nl"
  }, {
    path: "/nl/Brand",
    component: _4f1adcf1,
    name: "Brand___nl"
  }, {
    path: "/nl/Brands",
    component: _d0273b5c,
    name: "Brands___nl"
  }, {
    path: "/nl/cart",
    component: _0ad49e21,
    name: "cart___nl"
  }, {
    path: "/nl/checkout",
    component: _566790c7,
    name: "checkout___nl",
    children: [{
      path: "billing",
      component: _69996313,
      name: "billing___nl"
    }, {
      path: "payment",
      component: _0848b51e,
      name: "payment___nl"
    }, {
      path: "shipping",
      component: _094d35c6,
      name: "shipping___nl"
    }, {
      path: "thank-you",
      component: _1a49fd26,
      name: "thank-you___nl"
    }, {
      path: "user-account",
      component: _c7e83e0c,
      name: "user-account___nl"
    }]
  }, {
    path: "/nl/Cms",
    component: _2868639a,
    name: "Cms___nl"
  }, {
    path: "/nl/Contact",
    component: _5e22748a,
    name: "Contact___nl"
  }, {
    path: "/nl/customer",
    component: _091d3f3a,
    meta: {"titleLabel":"My Account"},
    name: "customer___nl",
    children: [{
      path: "addresses-details",
      component: _2bce4eb0,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___nl"
    }, {
      path: "backorder-items",
      component: _24ec6903,
      meta: {"titleLabel":"Backorders"},
      name: "customer-backorder-items___nl"
    }, {
      path: "credit-invoices",
      component: _6c4a3a76,
      meta: {"titleLabel":"Credit Invoices"},
      name: "customer-credit-invoices___nl"
    }, {
      path: "invoices",
      component: _7aaa47c6,
      meta: {"titleLabel":"Invoices"},
      name: "customer-invoices___nl"
    }, {
      path: "my-newsletter",
      component: _71f131bc,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___nl"
    }, {
      path: "my-profile",
      component: _753e29b8,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___nl"
    }, {
      path: "my-reviews",
      component: _27970228,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___nl"
    }, {
      path: "my-wishlist",
      component: _6b0388f0,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___nl"
    }, {
      path: "order-history",
      component: _47ab1230,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___nl"
    }, {
      path: "product-feeds",
      component: _208ebec6,
      meta: {"titleLabel":"Product feeds"},
      name: "customer-product-feeds___nl"
    }, {
      path: "quick-order",
      component: _8fd69190,
      meta: {"titleLabel":"Quick Order"},
      name: "quick-order___nl"
    }, {
      path: "addresses-details/create",
      component: _6fca8918,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___nl"
    }, {
      path: "/nl/reset-password",
      component: _dd2a473a,
      alias: "/customer/account/createPassword",
      name: "reset-password___nl"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _4b41c3e6,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___nl"
    }, {
      path: "order-history/:orderId",
      component: _6939c9ea,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___nl"
    }]
  }, {
    path: "/nl/CustomerSupport",
    component: _37409ffb,
    name: "CustomerSupport___nl"
  }, {
    path: "/nl/ForgotPassword",
    component: _2e0a7738,
    name: "ForgotPassword___nl"
  }, {
    path: "/nl/Home",
    component: _10ecd936,
    name: "Home___nl"
  }, {
    path: "/nl/Inspiration",
    component: _4f090d54,
    name: "Inspiration___nl"
  }, {
    path: "/nl/Login",
    component: _178dbfd3,
    name: "Login___nl"
  }, {
    path: "/nl/Page",
    component: _47bea915,
    name: "Page___nl"
  }, {
    path: "/nl/Register",
    component: _925237ae,
    name: "Register___nl"
  }, {
    path: "/nl/Search",
    component: _1a9c542e,
    name: "Search___nl"
  }, {
    path: "/nl/Storyblok",
    component: _5fd95485,
    name: "Storyblok___nl"
  }, {
    path: "/de/brand/:slug+",
    component: _4f1adcf1,
    name: "brand___de"
  }, {
    path: "/en/brand/:slug+",
    component: _4f1adcf1,
    name: "brand___en"
  }, {
    path: "/es/brand/:slug+",
    component: _4f1adcf1,
    name: "brand___es"
  }, {
    path: "/fr/brand/:slug+",
    component: _4f1adcf1,
    name: "brand___fr"
  }, {
    path: "/nl/brand/:slug+",
    component: _4f1adcf1,
    name: "brand___nl"
  }, {
    path: "/de/:slug+",
    component: _47bea915,
    name: "page___de"
  }, {
    path: "/en/:slug+",
    component: _47bea915,
    name: "page___en"
  }, {
    path: "/es/:slug+",
    component: _47bea915,
    name: "page___es"
  }, {
    path: "/fr/:slug+",
    component: _47bea915,
    name: "page___fr"
  }, {
    path: "/nl/:slug+",
    component: _47bea915,
    name: "page___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
