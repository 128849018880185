import { useScrollPageStore } from '~/stores/scrollpage';
export default function scrollBehavior(to, from, savedPosition) {
  const scrollPageStore = useScrollPageStore();

  const changePagePosition = scrollPageStore.changePagePosition

  let position = { x: 0, y: 0 }

  if(changePagePosition && savedPosition) {
    position = changePagePosition
    scrollPageStore.setChangePagePosition(null);
  } else if (savedPosition) {
    position = savedPosition
  } else if(!savedPosition && to.path !== from.path) {
    scrollPageStore.setChangePagePosition(null);
  }

  scrollPageStore.setScrollPosition(position);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (to.hash) {
        return resolve({ selector: to.hash });
      } else if (savedPosition) {
        return resolve(savedPosition);
      } else {
        resolve({ x: 0, y: 0 });
      }
    }, 800);
  });
}